var firebaseConfig = {
    apiKey: "AIzaSyARJhClRUouS0OCKm1YzdNna-ayyTRZjwU",
    authDomain: "eu-desenvolvo.firebaseapp.com",
    databaseURL: "https://eu-desenvolvo-default-rtdb.firebaseio.com",
    projectId: "eu-desenvolvo",
    storageBucket: "eu-desenvolvo.appspot.com",
    messagingSenderId: "641479293184",
    appId: "1:641479293184:web:573eb629593e8219bf5be5",
    measurementId: "G-LRG40YSPKF"
}

export default firebaseConfig